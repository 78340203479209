class Logger {
    public info(...args: any[]): void {
        if (this.enabled(30)) console.log('Info', ...args)
    }

    public warn(...args: any[]): void {
        if (this.enabled(40)) console.log('Warn', ...args)
    }

    public error(...args: any[]): void {
        if (this.enabled(50)) console.log('Error', ...args)
    }

    private enabled(level: number): boolean {
        return level > this.minLevel 
    }

    constructor() {
        const minLoggerLevel = +(window.localStorage.getItem('MIN_LOGGER') || '')
        this.minLevel = minLoggerLevel > -1 ? minLoggerLevel : 45
    }

    // levels from here: https://github.com/trentm/node-bunyan#levels
    private minLevel: number;
}

export const logger = new Logger();
